export interface IAbcWalletOptions{
    bappName: string;
    chainId: string;
    rpcUrl: string;
    email: string;
    id_token: string;
    serv: string;
}

const ConnectToAbcWallet = (AbcWalletProvider: any, opts: IAbcWalletOptions) => {
    return new Promise(async (resolve, reject) => {
        let bappName = ""
        let chainId = ""
        let rpcUrl = ""
        let email = ""
        let id_token = ""
        let serv = ""

        if (opts) {
            bappName = opts.bappName
            chainId = opts.chainId
            rpcUrl= opts.rpcUrl
            email= opts.email
            id_token= opts.id_token
            serv = opts.serv
        }

        const provider = new AbcWalletProvider({
            bappName,
            chainId,
            rpcUrl,
            email,
            id_token,
            serv
        });

        try {
            await provider.enable();
            resolve(provider);
        } catch (e) {
            reject(e);
        }
    })
}

export default ConnectToAbcWallet;
